<template>
	<div class="rest-closed">
		<img
			src="@/assets/img/timeOut.png"
			alt="Rest closed"
			class="rest-closed__img"
		>
		<div
			class="rest-closed__title"
			v-if="this.$store.state.closedRestaurantData.UF_TITLE"
		>
			{{ restTitle }}
		</div>
		<div
			class="rest-closed__time"
			v-if="tomorrow"
		>Блюда на сегодня закончились, смотрите меню на завтра
		</div>
		<button
			class="rest-closed__order-tomorrow btn"
			v-if="tomorrow && !currentBasket?.collective"
			@click="switchDay"
		>
			Заказать {{ isMealPage ? isMealPage : "обед" }} на завтра
		</button>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "RestClosed",
	props: {
		tomorrow: Boolean,
		isMealPage: String,
	},
	methods: {
		async switchDay()
		{
			await this.$store.dispatch("setDate", "tomorrow");
		},
	},
	computed:
		{
			...mapGetters({
				deliveryTime: "restaurant/getRestDeliveryTime",
				currentBasket: "getCurrentBasket",
			}),
			restTitle()
			{
				const time = this.isMealPage ? this.isMealPage + "ов" : "обедов";
				let restText = this.$store.state.closedRestaurantData.UF_TITLE.replace("#TIME_RANGE#", time);

				restText = restText.replace("#CONJUNCTIONS#", 'еще');

				return restText;
			}
		},
	mounted()
	{
		this.$store.dispatch("getBannerData");
	},
};
</script>

<style lang="scss">
.rest-closed
{
	width: 100%;
	padding-top: 30px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&__img
	{
		align-self: center;
		height: 300px;

		@media (max-width: 580px) {
			max-width: 350px;
			height: unset;
		}
	}

	&__title
	{
		margin-top: 24px;

		color: $textColor;
		font-size: 24px;
		font-weight: 700;
		line-height: 31px;
	}

	&__time
	{
		margin-top: 8px;

		color: $textColor;
		font-weight: 400;
		font-size: 16px;
		line-height: 20px;
	}

	&__order-tomorrow
	{
		align-self: center;
		margin-top: 24px;

		width: 298px;
		height: 56px;
	}

	@media (max-width: 567px)
	{
		padding-top: 24px;

		align-items: flex-start;

		&__order-tomorrow
		{width: 100%;}
	}
}
</style>
